<template>
  <div>
    <formText :data="{type: 'text', subtype: 'text', value: form[index].label, label: 'Überschrift'}" :callback="setLabel"/>
    <formRadio :data="{value: form[index].subtype, values: [{label: 'h1', value: 'h1'},{label: 'h2', value: 'h2'},{label: 'h3', value: 'h3'},{label: 'h4', value: 'h4'},{label: 'h5', value: 'h5'},{label: 'h6', value: 'h6'}]}" :callback="setSubtype"/>
  </div>
</template>
<script>
export default {
  components: {
    formText () { return import('@/components/formBuilder/parts/text') },
    formRadio () { return import('@/components/formBuilder/parts/radio') }
  },
  props: {
    form: Array,
    index: Number
  },
  data () {
    return {

    }
  },
  methods: {
    setLabel (data) {
      this.form[this.index].label = data
    },
    setSubtype (data) {
      this.form[this.index].subtype = data
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
